/** @jsx jsx */
import { jsx, Container, Grid } from "theme-ui";

// import { Link } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";

import NetworksImage from "../images/network.png";
import ComponentsImage from "../images/concept_map.png";
import IntegrationsImage from "../images/integrate_tools.png";

const HeroSection = () => (
  <div
    sx={{
      backgroundColor: "secondary",
      color: "white",
      p: 3,
      "text-align": "center",
    }}
  >
    <div sx={{ pb: 3 }}>
      <span
        sx={{
          bg: "logo-text",
          px: 3,
          py: 1,
          border: (theme) => `2px solid ${theme.colors["logo-text"]}`,
          borderRadius: "5px",
          fontSize: 4,
          lineHeight: "heading",
        }}
      >
        archive: project development is stopped, no released versions
      </span>
    </div>
    <div sx={{ margin: "0 auto", maxWidth: (t) => t.sizes.container }}>
      <h1 sx={{ fontSize: 1 }}>
        <p sx={{ fontSize: 2 }}>
          Is a unique and powerful way to visually organize information and
          ideas.
        </p>
      </h1>

      <div>
        It is designed to fight information overload, while providing meaningful
        access to your data. All done in the next generation web environment,
        that makes it easier to navigate ideas and make right decisions.
      </div>
    </div>
  </div>
);

const Section = ({ left, right, children }) => (
  <Container p={2}>
    <Grid gap={2} columns={[1, 2]} sx={{ alignItems: "center" }}>
      {left}
      {children}
      {right}
    </Grid>
  </Container>
);

const GraphOfIdeas = () => (
  <Section left={<img src={NetworksImage} alt="network of ideas" />}>
    <div>
      <b>Graph of ideas</b>
      <p>
        Connect and arrange key concepts in a way that makes sense to you. Keep
        your information as a knowledge graph.
      </p>
    </div>
  </Section>
);

const ComponentsForWeb = () => (
  <Section right={<img src={ComponentsImage} alt="components" />}>
    <div>
      <b>Modern visual components for Web</b>
      <p>
        Use beautiful visual techniques to represent your ideas through graphs.{" "}
        <br />
        Combine mind maps, flow charts and other types of diagrams.
      </p>
    </div>
  </Section>
);

const Integrations = () => (
  <Section left={<img src={IntegrationsImage} alt="integrations" />}>
    <div>
      <b>Integrate with existing tools</b>
      <p>
        Connect with the places your data lives: Evernote, Google Keep, Dropbox
        and more! Store your data anywhere; think with visual maps when you need
        a clear picture.
      </p>
    </div>
  </Section>
);

const MoreInfo = () => (
  <div sx={{ pt: 3 }}>
    <Container p={2}>
      <p sx={{ fontSize: 2, lineHeight: "heading" }}>Visualize your thoughts</p>
      <center>Unravel your ideas. Pinpoint and prioritize content.</center>
      <div sx={{ mb: 3 }} />
    </Container>
    <GraphOfIdeas />
    <ComponentsForWeb />
    <Integrations />
  </div>
);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <MoreInfo />
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
);

export default IndexPage;
